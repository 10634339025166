import * as React from 'react';
import { useStyles } from '../../common/theme';
import { Avatar, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import Utility from '../../Global/Utility';

type SearchPopoverProps = {
    email: string,
    inputRef: any,
    handleEmailChange: any,
    handleEmailSelect: any
    emailList?: string[],
    showList: boolean,
    setShowList: any
}

export default function SearchPopover({
    email,
    inputRef,
    handleEmailChange,
    handleEmailSelect,
    emailList,
    showList,
    setShowList,
}: SearchPopoverProps) {
    const theme: any = useTheme();
    const classes = useStyles();


    const handleSelect = (email: string) => {
        handleEmailSelect(email);
        inputRef.current.focus();
    }


    const handleInputChange = (e: any) => {
        handleEmailChange(e)
    }

    const onClickHandler = (e: any): any => {
        e.preventDefault();
        e.stopPropagation();
        setShowList(true);
        inputRef.current.focus();
    }


    function fuzzySearch(query: string, items: string[] | undefined) {
        const lowercaseQuery = query.toLowerCase();
        const search = items?.filter(item => item.toLowerCase().includes(lowercaseQuery))
        const validEmail = Utility.isValidEmail(query) ? [query] : [];
        return search?.length ? search : validEmail;
    }

    const checkIfExternalUser = (emailId: string) => {
        return emailList?.includes(emailId) ? theme?.palette?.primary?.main : 'red'
    }


    return (
        <div
            style={{
                position: 'relative'
            }}
            onClick={onClickHandler}
        >
            <input
                type='text'
                ref={inputRef}
                style={{
                    marginTop: '5px',
                    width: '100%'
                }}
                value={email}
                onChange={handleInputChange}
                onKeyDown={(e) => e.stopPropagation()}
                placeholder='Enter emails to share'
                className={classes.input}
            />
            {
                showList && email && <div style={{
                    position: 'absolute',
                    width: 'max-content',
                    height: 'max-content',
                    top: '110%',
                    backgroundColor: 'transparent',
                    zIndex: '10'
                }}>
                    <List
                        sx={{
                            width: 'max-content',
                            maxHeight: '200px',
                            overflow: 'auto',
                            bgcolor: `white`,
                            boxShadow: `0px 0px 8px black`,
                            borderRadius: '8px'
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {fuzzySearch(email, emailList)?.map(emailId => {
                            return <ListItemButton
                                key={emailId}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleSelect(emailId)
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: '40px',
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            backgroundColor: checkIfExternalUser(emailId),
                                            width: '30px',
                                            height: '30px'
                                        }}
                                    >{emailId.toUpperCase()[0]}</Avatar>
                                </ListItemIcon>
                                <ListItemText primary={emailId} />
                            </ListItemButton>
                        })}
                    </List>

                </div>
            }
        </div >
    );
}
