import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, TextField, Modal, Typography, Button, Box, IconButton, SvgIcon } from '@mui/material';
import { useStyles } from '../../common/theme';
import { RemoveIcon } from '../../assets/images/SvgImages'
import Constant from '../../Global/Constant';
import LoaderIcon from '../Loader/LoderIcon';

type AddAppModalProps = {
    open: boolean,
    handleClose: () => any,
    saveProject: (projectName: string) => any,
    rename?: boolean
}


export default function CreateProjectModal({ open, handleClose, saveProject, rename }: AddAppModalProps) {
    const [projectName, setProjectName] = useState("");
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);

    const handlePlatform = (name: string) => {
        if (!name.trim()) {
            setErrors(true);
        }
        else {

            setErrors(false);
        }
        setProjectName(name);
    }


    const resetStates = () => {
        setProjectName("");
        setErrors(false);
    }

    const handleCreatePoject = async (projectName: string) => {
        setLoading(true);
        if (!projectName.trim()) {
            setErrors(true);
            return;
        };
        await saveProject(projectName.trim());
        resetStates();
        setLoading(false);
    }

    const handleModalClose = () => {
        handleClose();
        resetStates();
    }

    const classes = useStyles();

    return (
        <div>
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ width: 400 }} className={classes.modalBox}>
                    <Grid direction={'column'} container gap={'16px'}>
                        <Grid item>
                            <Typography variant='fontBoldLarge' >{rename ? "Rename Project" : "Create Project"}</Typography >
                            <IconButton
                                aria-label="close"
                                onClick={handleModalClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 10,
                                }}
                            >
                                <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
                            </IconButton>
                        </Grid>
                        <Grid item >
                            <TextField
                                required
                                size='small'
                                label="Project Name"
                                fullWidth
                                margin='none'
                                color='primary'
                                autoComplete={undefined}
                                helperText={errors ? Constant.emptyFieldError : ""}
                                error={errors}
                                onChange={(e) => { handlePlatform(e.target.value); }}
                            />
                        </Grid>
                        <Grid
                            item xs={12} textAlign={'center'}
                        >
                            <Button
                                variant='contained'
                                size='small'
                                fullWidth
                                onClick={() => handleCreatePoject(projectName)}
                            >
                                <Typography variant='fontBold' color='white'>{loading ? <LoaderIcon /> : (rename ? "Rename" : "Create")}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div >
    );
}