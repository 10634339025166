/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, action, makeObservable } from "mobx";
import Constant from "../Global/Constant";
import authService from "../Services/authService";
import Utility from "../Global/Utility";
import { TokenType } from "../Global/Interfaces";
import { useNavigate } from "react-router-dom";
import Routes from "../Global/Routes";

type userType = {
    name: string;
    email: string;
    uid: string;
    institutionName: string;
    userAccess: Array<string>;
};

const getCurrentUser = () => {
    if (localStorage.token) {
        return Utility.parseJwt(localStorage.token);
    }

    return null;
};

class AuthStore {
    email: string = "";
    password: string = "";
    error: string = "";
    authListener: ((user: any) => void) | null = null;
    currentUser: userType | null = null;

    constructor() {
        makeObservable<any>(this, {
            email: observable,
            password: observable,
            error: observable,
            handleUserNameChange: action,
            handlePasswordChange: action,
        });
    }

    handleUserNameChange(email: string) {
        this.email = this.email.trimLeft();
    }

    handlePasswordChange(password: string) {
        this.password = password.trimLeft();
    }

    getCurrentUser() {
        const token = localStorage.getItem(Constant.token);
        if (!token) return null;
        const user = Utility.parseJwt(token);
        localStorage.setItem(Constant.token, token);
        return user as TokenType;
    }

    addAuthListener(callbackListener: (user: any) => void) {
        this.authListener = callbackListener;

        this.currentUser = getCurrentUser();
        if (this.authListener) {
            this.authListener(this.currentUser);
        }
    }

    clearTextField() {
        this.email = "";
        this.password = "";
        this.error = "";
    }

    async signIn(email: string, password: string) {
        try {
            const resp = await authService.login({ email, password });
            if (resp && resp.data && resp?.data?.token) {
                const { token, refreshToken } = resp.data;
                const user = Utility.parseJwt(token);
                localStorage.setItem(Constant.token, token);
                localStorage.setItem(Constant.refreshToken, refreshToken);
                this.currentUser = user;
                return Promise.resolve();
            } else {
                throw new Error(Constant.loginError);
            }
        } catch (err: any) {
            let errorMsg = Constant.loginError;
            if (err && err.response && err.response.data) {
                errorMsg = err.response.data;
            }
            throw new Error(errorMsg);
        }
    }

    signOut() {
        localStorage.removeItem("token");

        this.currentUser = null;
        if (this.authListener) {
            this.authListener(this.currentUser);
        }
    }

    async forgotPasswordRequest(
        email: string,
        callback: (err?: Error) => void
    ) {
        try {
            await authService.forgotPasswordRequest(email);
            callback();
        } catch (err: any) {
            let errorMsg = Constant.defaultErrorMessage;
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.error
            ) {
                errorMsg = err.response.data.error.message;
            }
            callback(new Error(errorMsg));
        }
    }

    async resetPassword(
        password: string,
        userId: string,
        token: string,
        callback: (err?: Error) => void
    ) {
        try {
            const data = { password, token, userId };

            await authService.resetPassword(data);
            callback();
        } catch (err: any) {
            let errorMsg = Constant.defaultErrorMessage;
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.error
            ) {
                errorMsg = err.response.data.error.message;
            }
            callback(new Error(errorMsg));
        }
    }
}

export default new AuthStore();
