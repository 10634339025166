import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ProjectDetails, ProjectType } from '../../Global/Interfaces';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, CircularProgress, Grid } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EmptyImage from "../../assets/images/img_register.png";
import { useLocation } from 'react-router-dom';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type SideBarProps = {
  open: boolean,
  setOpen: (status: boolean) => any,
  projects?: ProjectType,
  selectedProject?: ProjectDetails,
  setSelectedProject: any,
  openProjectModal: (rename: boolean) => any,
}

export default function SideBar({ open, openProjectModal, projects, selectedProject, setSelectedProject }: SideBarProps) {

  const location = useLocation();
  const projectFromRouter = location.state?.projectDetails;
  useEffect(() => {
    if (projectFromRouter) {
      setSelectedProject(projectFromRouter);
    }
    else
      setSelectedProject({ ...projects?.myProjects[0] });
  }, [projects])

  const EmptyProjects = (shared: boolean) => {
    return <Grid container direction={'column'} alignItems={'center'}>
      <Grid item>
        <img
          src={EmptyImage}
          alt='empty'
          height={'50px'}
          width={'50px'}
        />
      </Grid>
      <Grid item>
        <Typography
          variant='fontPrimary'
          color={'grey'}
        >
          {shared ? "No Projects Shared with you" : "No projects, please create new"}
        </Typography>
      </Grid>
    </Grid>
  }

  function structuredProjects(project: ProjectDetails) {
    return (
      <ListItem key={project.projectId}
        sx={{
          padding: 0,
          '& .MuiButtonBase-root': {
            height: '44px',
            backgroundColor:
              project.projectId === selectedProject?.projectId &&
                project.shared === selectedProject.shared ? 'rgba(224, 224, 224, 1)' : 'white',
            margin: '0px 10px',
            borderRadius: '10px',
            padding: 0
          }
        }}
      >
        <ListItemButton
          sx={{ paddingLeft: "10px" }}
          onClick={() => setSelectedProject({ ...project })}
        >
          <ListItemIcon sx={{ minWidth: '20px', margin: '10px', paddingLeft: 0 }}>
            {
              selectedProject?.projectId === project.projectId &&
              project.shared === selectedProject.shared &&
              <SvgIcon fontSize='small'>
                <MenuIcon fontSize='small' />
              </SvgIcon>
            }
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: '14px' }}
            primary={
              <Typography variant='fontPrimary'>{project.projectName}</Typography>
            }
          />
          <ListItemIcon sx={{ minWidth: '20px', margin: '10px', paddingLeft: 0 }}>
            {
              project?.sharedToOthers &&
              <SvgIcon fontSize='small'>
                <LinkIcon fontSize='small' />
              </SvgIcon>
            }
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    )
  }

  function projectList(project: ProjectDetails[], shared: boolean) {
    return (
      <Accordion
        sx={{
          marginBottom: 2,
          minHeight: 0,
          border: 'none',
          boxShadow: 'none',
          '& .MuiPaper-root': {
            margin: 0
          },
          '& .MuiButtonBase-root': {
            minHeight: '0',
          },
        }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: "black" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            minHeight: 0,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              padding: 0,
              margin: '4px 0',
            },
          }}
        >
          <Typography
            color={"black"}
            marginLeft={"5px"}
            variant='fontBoldSmall'
          >
            {shared ? "SHARED WITH ME" : "MY PROJECTS"}
          </Typography>
        </AccordionSummary>
        {
          project.length ?
            <AccordionDetails sx={{ padding: 0 }}> {project.map(project => structuredProjects(project))} </AccordionDetails>
            : EmptyProjects(shared)
        }
      </Accordion>
    );
  }

  return (
    <Box sx={{ display: 'flex', width: '300px' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: '100%',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '300px',
            boxSizing: 'border-box',
          },
          '& .MuiPaper-root': {
            backgroundColor: "white",
            margintTop: '60px',
            bottom: '0px',
            zIndex: '50'
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Button
          sx={{ textTransform: 'none', margin: '16px', marginTop: '100px', height: '44px' }}
          variant='contained'
          size='small'
          onClick={() => {
            openProjectModal(false);
          }}
          startIcon={<AddIcon sx={{ color: 'white' }} />}
        >
          <Typography variant='fontBold' color={'white'}>Create Project</Typography>
        </Button>
        <List sx={{
          height: '100%',
          '& .MuiPaper-root': {
            backgroundColor: "white",
            marginTop: '0px !important',
          },
        }} >
          {
            projects?.myProjects ? "" :
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
          }
          {projects?.myProjects && projectList(projects.myProjects, false)}
          {projects?.sharedProjects && projectList(projects.sharedProjects, true)}
        </List>
      </Drawer>
    </Box >
  );
}