import * as React from 'react';
import { useState } from 'react';
import { Button, Typography, Modal, Grid, IconButton, SvgIcon, CircularProgress } from '@mui/material';
import { useStyles } from '../../common/theme';
import { RemoveIcon } from '../../assets/images/SvgImages'
import LoaderIcon from '../Loader/LoderIcon';

const style = {
    width: "334px",
    border: 'none',
};

type AddAppModalProps = {
    handleConfirm: (data: any) => any;
}

const ConfirmModal = React.forwardRef(({ handleConfirm }: AddAppModalProps, ref) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [heading, setHeading] = useState("");
    const [message, setMessage] = useState("");
    const [buttonName, setButtonName] = useState("");
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    React.useImperativeHandle(ref, () => {
        return {
            openDeleteModal: (heading: string, message: string, buttonName: string, data: any) => {
                setOpen(true);
                setData(data);
                setHeading(heading);
                setMessage(message);
                setButtonName(buttonName);
            }
        }
    }, [])

    const handleDeleteConfirm = async () => {
        setLoading(true);
        await handleConfirm(data)
        setOpen(false);
        setLoading(false);
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid direction={'column'} container sx={style} className={classes.modalBox} >
                    <Grid container alignSelf={'left'} marginBottom={'10px'} alignItems={'left'}>
                        <Typography variant='fontBoldLarge'>{heading}</Typography>
                        <IconButton
                            size='small'
                            sx={{
                                marginLeft: 'auto',
                                position: 'absolute',
                                right: 10,
                                top: 10
                            }}
                            onClick={() => { setOpen(false) }}

                        >
                            <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant='fontPrimary'>{message}</Typography >
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        textAlign={'center'}
                        marginTop={'15px'}
                        marginLeft={'auto'}
                        gap={'15px'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Button variant='contained' size='small'
                            sx={{
                                textTransform: 'none',
                                backgroundColor: 'rgba(246, 97, 97, 1)',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'rgba(230, 1, 1, 1)'
                                },
                                width: '100%'
                            }}
                            onClick={() => { handleDeleteConfirm() }}
                        >
                            <Typography variant='fontBold'>{loading ? <LoaderIcon /> : buttonName}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </div >
    );
});

export default ConfirmModal;