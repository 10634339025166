import React, { ReactNode, forwardRef, useState, useImperativeHandle } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, IconButton } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

function RemoveIcon(props: SvgIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <circle cx="14" cy="14" r="14" fill="#E0E0E0" />
            <path d="M10 17.999L17.9991 9.99988" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 17.999L10.0009 9.99988" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}

const NotificationService = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<any>("")
    const [message, setMessage] = useState('');
    const [duration, setDuration] = useState<number | null>(null);

    const handleClose = () => {
        setOpen(false);
        setType("");
        setMessage("");
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <RemoveIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    useImperativeHandle(ref, () => {
        return {
            showNotification(newMessage: string, type: string, duration: number | null) {
                setMessage(newMessage);
                setOpen(true);
                setType(type);
                setDuration(duration);
            }
        }
    }, []);

    return (
        message ? <Snackbar
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            action={action}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
            < Alert onClose={handleClose} variant='filled' severity={type} >
                {message}
            </Alert >
        </Snackbar >
            : <></>
    );
});

export default NotificationService;