import axios from "axios";
import authStore from "../Store/authStore";
// import uploadStore from '../Store/uploadStore';
import { AxiosRequestConfig } from "axios";
import Constant from "../Global/Constant";
import Routes from "../Global/Routes";

class API {
    constructor() {
        axios.defaults.baseURL = Constant.REACT_APP_BASE_URL;
        // Use this to inject anything with all the request
        axios.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem(Constant.token);
                if (token) {
                    const item = { ...config };
                    item.headers.Authorization = `Bearer ${token}`;
                    return item;
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error && error.response && error.response.status === 401) {
                    try {
                        const originalRequest = error.config;
                        const token = localStorage.getItem(Constant.token);
                        const refreshToken = localStorage.getItem(
                            Constant.refreshToken
                        );
                        const response = await axios.post(
                            "/User/RefreshToken",
                            {
                                token,
                                refreshToken,
                            }
                        );
                        const {
                            token: newToken,
                            refreshToken: newRefreshToken,
                        } = response.data;

                        originalRequest.headers[
                            "Authorization"
                        ] = `Bearer ${newToken}`;

                        localStorage.setItem(Constant.token, newToken);
                        localStorage.setItem(
                            Constant.refreshToken,
                            newRefreshToken
                        );
                        return this.authAxios(originalRequest);
                    } catch (err: any) {
                        this.logout();
                    }
                }
                return Promise.reject(error);
            }
        );
    }

    authAxios = axios.create();

    async get(url: string, config?: AxiosRequestConfig) {
        return axios.get(url, config);
    }

    async post(url: string, data: any) {
        return axios.post(url, data);
    }

    async postMultipartData(url: string, data: any) {
        return axios.post(url, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    async postMultipartWithBufferResponse(
        url: string,
        data: any,
        config?: AxiosRequestConfig
    ) {
        return axios.post(url, data, config);
    }

    async put(url: string, data: any) {
        return axios.put(url, data);
    }

    async delete(url: string) {
        return axios.delete(url);
    }

    logout = () => {
        localStorage.removeItem(Constant.token);
        localStorage.removeItem(Constant.refreshToken);
        window.location.href = Routes.login;
    };
}
export default new API();
