import * as React from 'react';
import { useEffect, useState } from 'react';
import { Menu, MenuItem, Grid, IconButton, ListItemText, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import authStore from '../../Store/authStore';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import Routes from '../../Global/Routes';
import ChangePasswordModal from '../Modal/ChangePassword';
import EditProfileModal from '../Modal/EditProfile';
import authService from '../../Services/authService';
import { UserDetails } from '../../Global/Interfaces';

type AccountMenuProps = {
    handleLogout: () => any
}

export default function AccountMenu({ handleLogout }: AccountMenuProps) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme: any = useTheme();
    const [profileDetails, setProfileDetails] = useState<UserDetails>({
        firstName: '',
        lastName: '',
        email: '',
        organization: ''
    })
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutUser = () => {
        handleClose();
        handleLogout();
    }

    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const changePassword = () => setPasswordOpen(true);
    const [editProfileOpen, setEditProfileOpen] = React.useState(false);
    const ediProfile = () => setEditProfileOpen(true);

    const menuStyle = {
        borderBottom: `1px solid ${theme.palette.secondary.main}`
    }

    useEffect(() => {
        getEditProfileFields();
    }, [])

    const getEditProfileFields = async () => {
        try {

            const response = await authService.getUserDetails();
            const userDetails = response.data;
            setProfileDetails({ ...userDetails, email: authStore.getCurrentUser()?.email })
        }
        catch (error) {
        }
    }

    return (
        <>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size='small'
            >
                <AccountCircleIcon fontSize='large' sx={{ width: '44px', height: '44px', color: theme.palette.secondary.main }} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    paddingLeft: '12px',
                    width: '500px',
                    borderRadius: '8px'
                }}
            >
                <MenuItem sx={{ ...menuStyle }} onClick={handleClose}>
                    <ListItemText >
                        <Grid container>
                            <Grid item>
                                <Typography
                                    variant='fontBoldLarge'
                                    fontSize={'18px'}
                                >
                                    {profileDetails.firstName} {profileDetails.lastName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant='fontPrimary'
                                    sx={{
                                        color: 'rgba(112, 112, 112, 1)',
                                    }}
                                >
                                    {profileDetails.organization}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemText>
                </MenuItem>
                <MenuItem sx={{ ...menuStyle }} onClick={ediProfile}>
                    <ListItemText>
                        <Typography variant='fontPrimary'>Edit Profile</Typography>
                    </ListItemText>
                </MenuItem>
                <MenuItem sx={{ ...menuStyle }} onClick={changePassword}>
                    <ListItemText>
                        <Typography variant='fontPrimary'>Change Password</Typography>
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={logoutUser}>
                    <ListItemText>
                        <Typography variant='fontPrimary'>Logout</Typography>
                    </ListItemText>
                </MenuItem>
            </Menu >
            <ChangePasswordModal passwordOpen={passwordOpen} setPasswordOpen={setPasswordOpen} />
            <EditProfileModal
                editProfileOpen={editProfileOpen}
                profileDetails={profileDetails}
                setEditProfileOpen={setEditProfileOpen}
                getEditProfileFields={getEditProfileFields}
            />
        </>
    );
}