const Routes = {
    project: "/project/:projectId?",
    projectDefault: "/project",
    home: "/",
    app: "/app",
    registerUser: "/register",
    platform: "/platform/:platformId",
    login: "/login",
    users: "/users",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password/:uid/:token",
    createPassword: "/create-password/:uid/:token",
    notFound: "/404",
    userRole: "/userRole",
    notAuthorized: "/403",
    successPage: "/success",
    status: "/status",
    notExist: "/notExist",
    feed: "/feed",
    myGallery: "/myGallery",
    review: "/review",
    devices: "/devices",
    media: "/media",
};

export default Routes;
