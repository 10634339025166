import React, { useRef, } from 'react';
import { Box, Grid, Typography, styled, } from '@mui/material';
import AccountMenu from './Account';
import ConfirmModal from '../Modal/ConfirmModal';
import { DeleteModalRefType, ProjectType } from '../../Global/Interfaces';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Routes from '../../Global/Routes';
import { useNavigate } from 'react-router-dom';
import SearchProjects from './SearchProjects';

type HeaderProps = {
  open: boolean,
  setOpen: (status: boolean) => any,
  hideMenu?: boolean,
  projects?: ProjectType,
  setSelectedProject?: any
}

function Header({ open, setOpen, projects, setSelectedProject }: HeaderProps) {
  const logoutConfirmRef = useRef<DeleteModalRefType>(null)
  const navigate = useNavigate();
  const HeaderWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
  }>(({ theme, open }) => ({
    flexGrow: 1,
    padding: "0 2px",
    zIndex: 100,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: '0',
    }),
    backgroundColor: 'white'
  }));

  const handleLogout = async () => {
    logoutConfirmRef.current?.openDeleteModal(
      Constant.logOut,
      Constant.logoutConfirm,
      Constant.logOut, null
    );
  }

  const logOutUser = () => {
    authStore.signOut();
    navigate(Routes.login);
  }


  return (
    <>
      <HeaderWrapper open={open} sx={{ width: '100%' }}>
        <Box sx={{ height: "60px", borderBottom: '1px solid rgba(214, 214, 214, 1)' }} >
          <Grid
            container
            alignItems={"center"}
            padding={"0 20px"}
            height={"100%"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Typography fontSize={'24px'} variant='fontBold'> HotFix </Typography>
            </Grid>
            <Grid item>
              <SearchProjects setSelectedProject={setSelectedProject} projectDetails={projects} />
            </Grid>
            <Grid item>
              <AccountMenu handleLogout={handleLogout} />
            </Grid>
          </Grid>
        </Box>
      </HeaderWrapper>
      <ConfirmModal handleConfirm={logOutUser} ref={logoutConfirmRef} />
    </>
  );
}
export default Header;
