import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Grid, Modal, Typography, Box, SvgIcon, TextField, Button, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useStyles } from '../../common/theme';
import { RemoveIcon } from '../../assets/images/SvgImages'
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import { NotificationRefType, UserDetails } from '../../Global/Interfaces';
import authService from '../../Services/authService';
import authStore from '../../Store/authStore';
import NotificationService from '../Notification/Notification';
import LoaderIcon from '../Loader/LoderIcon';

type EditProfileProps = {
    setEditProfileOpen: (open: boolean) => any,
    editProfileOpen: boolean,
    profileDetails: UserDetails,
    getEditProfileFields: () => any
}

export default function EditProfileModal({ setEditProfileOpen, editProfileOpen, profileDetails, getEditProfileFields }: EditProfileProps) {
    const classes = useStyles();
    const editProfileClose = () => setEditProfileOpen(false);
    const [loading, setLoading] = useState(false);
    const notificaitonRef = useRef<NotificationRefType>(null);

    useEffect(() => {
        setFormData(profileDetails);
    }, [profileDetails])

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
    });


    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));
    };

    const validateField = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'firstName':
                return value.length < 1 ? Constant.emptyFieldError : '';
            case 'lastName':
                return value.length < 1 ? Constant.emptyFieldError : '';
            case 'email':
                return Utility.isValidEmail(value) ? '' : Constant.invalidEmail;
            case 'organization':
                return value.length < 1 ? Constant.emptyFieldError : '';
        }
    };



    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const newErrors: any = {};
            Object.keys(formData).forEach((fieldName: string) => {
                newErrors[fieldName] = validateField(fieldName, (formData as any)[fieldName]);
            });
            setErrors(newErrors);

            if (Object.values(newErrors).every((error) => error === '')) {
                setLoading(true);
                await authService.editUserProfile(formData);
                await getEditProfileFields();
                setLoading(false);
                editProfileClose();
                notificaitonRef.current?.showNotification(Constant.profileUpdateSuccess, "success", Constant.twoSeconds);
            }
        }
        catch (error: any) {
            notificaitonRef.current?.showNotification(error?.message || Constant.defaultErrorMessage, "error");
        }
    };


    const checkError = (field: any) => {
        return field ? true : false;
    }



    return (
        <div>
            <Modal
                open={editProfileOpen}
                onClose={editProfileClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={{ width: 400 }} className={classes.modalBox}>
                        <Grid direction={'column'} gap={'16px'}>
                            <Grid item>
                                <Typography width={'100%'} variant='fontBoldLarge' >Edit Profile</Typography >
                                <IconButton
                                    aria-label="close"
                                    onClick={editProfileClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 16,
                                        top: 12,
                                    }}
                                >
                                    <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
                                </IconButton>
                            </Grid>
                            <Grid textAlign={'center'} item >
                                <TextField
                                    required
                                    size='small'
                                    label="First Name"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    color='primary'
                                    name='firstName'
                                    helperText={errors.firstName}
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    error={checkError(errors.firstName)}
                                    autoComplete={undefined}
                                />
                            </Grid>
                            <Grid textAlign={'center'} item >
                                <TextField
                                    required
                                    size='small'
                                    label="Last Name"
                                    name='lastName'
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    color='primary'
                                    helperText={errors.lastName}
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    error={checkError(errors.lastName)}
                                    autoComplete={undefined}
                                />
                            </Grid>
                            <Grid textAlign={'center'} item >
                                <TextField
                                    required
                                    size='small'
                                    label="Email"
                                    fullWidth
                                    name='email'
                                    sx={{ mt: 2 }}
                                    color='primary'
                                    helperText={errors.email}
                                    value={formData.email}
                                    onChange={handleChange}
                                    disabled
                                    error={checkError(errors.email)}
                                    autoComplete={undefined}
                                />
                            </Grid>
                            <Grid textAlign={'center'} item >
                                <TextField
                                    required
                                    size='small'
                                    label="Organization Name"
                                    name='organization'
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    color='primary'
                                    helperText={errors.organization}
                                    value={formData.organization}
                                    onChange={handleChange}
                                    error={checkError(errors.organization)}
                                    autoComplete={undefined}
                                />
                            </Grid>
                            <Grid
                                item xs={12} textAlign={'center'}
                            >
                                <Button variant='contained' size='small'
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    onClick={handleSubmit}
                                >
                                    <Typography variant='fontBold' color={'white'}>{loading ? <LoaderIcon /> : "Update"}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            </Modal>
            <NotificationService ref={notificaitonRef} />
        </div >
    );
}