
import React, { useState } from 'react';
import { Typography, styled, Autocomplete, TextField, SvgIcon } from '@mui/material';
import { ProjectDetails, ProjectType, optionType } from '../../Global/Interfaces';
import { IosSvg, AndroidSvg } from "../../assets/images/SvgImages"
import Constant from '../../Global/Constant';
import { useNavigate } from 'react-router-dom';
type SearchProjectsProps = {
    projectDetails?: ProjectType,
    setSelectedProject?: any
}

function SearchProjects({ projectDetails, setSelectedProject }: SearchProjectsProps) {
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.secondary.dark,
        backgroundColor: 'white',
    }));

    const GroupItems = styled('ul')(({ theme }) => ({
        padding: 0,
    }));

    const handleOptionClick = (option: optionType) => {
        if (option.type === "BUNDLE") {
            window.location.href = `/app?appId=${option.id}&platform=${option.bundleType}`;
        }
        if (option.type === "PROJECT") {
            const projectDetails = { projectId: option.id, projectName: option.title, shared: option.shared };
            if (setSelectedProject)
                setSelectedProject(projectDetails)
            else {
                navigate(`/project`, {
                    state: {
                        projectDetails
                    }
                });
            }
        }
    };

    const formatProject = (projects: ProjectDetails[]) => {
        const formattedData: optionType[] = [];
        projects.forEach(project => {
            formattedData.push({
                type: "PROJECT",
                title: project.projectName,
                id: project.projectId,
                shared: project.shared
            })
            project.apps.forEach(app => {
                formattedData.push({
                    type: 'BUNDLE',
                    title: app.bundleId,
                    id: app.id,
                    bundleType: app.platform
                })
            })
        })
        return formattedData;
    }

    const optionSet = () => {
        if (!projectDetails) return [];
        const projects = formatProject(projectDetails.myProjects);
        const sharedProjects = formatProject(projectDetails.sharedProjects);
        return [...projects, ...sharedProjects];
    }

    const filterOptions = (options: any, { inputValue }: { inputValue: string }) => {
        if (inputValue !== '') {
            return optionSet().filter(option =>
                option.title.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
        return [];
    };

    const addAppIcon = (appType: 'IOS' | 'Android' | undefined) => {
        if (!appType) return;
        return <SvgIcon sx={{ marginRight: '5px' }}>
            {appType === Constant.Android ? AndroidSvg : IosSvg}
        </SvgIcon>
    }

    return (
        <Autocomplete
            size='small'
            id="free-solo-2-demo"
            disableClearable
            options={optionSet().sort((a, b) => -a.type.localeCompare(b.type))}
            groupBy={(option) => option.type}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
            sx={{ width: 300 }}
            onInputChange={(event, newInputValue) => {
                event?.preventDefault();
                setInputValue(newInputValue);
            }}
            open={inputValue.length > 0 ? true : false}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>
                        <Typography variant='fontBold'>
                            {params.group}
                        </Typography>
                    </GroupHeader>
                    <GroupItems>
                        <Typography variant='fontPrimary'>
                            {params.children}
                        </Typography>
                    </GroupItems>
                </li>
            )}
            renderInput={(params) => <TextField autoComplete='off' {...params} label="Search" />}
            renderOption={(props, option, { selected }) => (
                <li key={option.id} {...props} onClick={() => handleOptionClick(option)}>
                    {addAppIcon(option.bundleType)}<Typography variant='fontPrimary'>{option.title}</Typography>
                </li>
            )}
        />
    );
}
export default SearchProjects;
