import api from "./api";

class ProjectService {
    getAllProjects = async (emailId: string) => {
        return api.get(`/project/getAllProjects?email=${emailId}`);
    };

    createNewProject = async (data: {
        projectName: string;
        emailId: string;
    }) => {
        return api.post("/project/createProject", data);
    };

    renameProject = async (data: {
        projectId: number;
        projectName: string;
        emailId: string;
    }) => {
        return api.put("/project/renameProject", data);
    };

    addNewApp = async (data: {
        appType: string;
        bundleId: string;
        projectId: number;
        emailId: string;
    }) => {
        return api.post("/app/createApp", data);
    };

    getProjectDetails = async (projectId: number) => {
        return api.get(`/project/getProjectDetails?projectId=${projectId}`);
    };

    getAppDetails = async (data: { appId: string; platform: string }) => {
        return api.get(
            `/app/getAppDetails?appId=${data.appId}&platform=${data.platform}`
        );
    };

    publishAppVersion = async (data: {
        versionId: number;
        platform: string;
        published: boolean;
    }) => {
        return api.put(`/app/publishVersion`, data);
    };

    deleteAppVersion = async (data: {
        versionId: number;
        platform: string;
    }) => {
        return api.delete(
            `/app/deleteVersion?versionId=${data.versionId}&platform=${data.platform}`
        );
    };

    deleteApp = async (data: { appTableId: number; platform: string }) => {
        return api.delete(
            `/app/deleteApp?appTableId=${data.appTableId}&platform=${data.platform}`
        );
    };

    deleteProject = async (data: { projectId: number }) => {
        return api.delete(`/project/deleteProject?projectId=${data.projectId}`);
    };

    uploadBundleFile = async (formData: FormData) => {
        return api.post(`/uploadFile/uploadFileToS3`, formData);
    };

    leaveProject = async (data: { projectId: number; emailId: string }) => {
        return api.delete(
            `sharedProject/leaveProject?projectId=${data.projectId}&emailid=${data.emailId}`
        );
    };

    shareProject = async (data: {
        projectId: number;
        emails: string[];
        accessType: string;
    }) => {
        return api.post(`sharedProject/shareProject`, data);
    };

    getSharedProjectDetails = async (projectId: number) => {
        return api.get(
            `sharedProject/sharedProjectDetails?projectId=${projectId}`
        );
    };

    removeAccess = async (data: { projectId: number; emailId: string }) => {
        return api.delete(
            `SharedProject/RemoveAccess?projectId=${data.projectId}&emailId=${data.emailId}`
        );
    };

    changeAccess = async (data: {
        projectId: number;
        emailId: string;
        accessType: string;
    }) => {
        return api.put(`sharedProject/changeAccess`, data);
    };
}

export default new ProjectService();
