/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

//import config from '../Global/Config';
import { User } from "../Global/Interfaces";
import api from "./api";

class AuthService {
    login = async (data: any) => api.post("/user/login", data);
    forgotPasswordRequest = async (email: string) =>
        api.put("/User/ResetPassword", { email });
    resetPassword = async (data: any) =>
        api.post("/request/changePassword", data);
    registerUser = async (data: User) => api.post("/User/register", data);
    changePassword = async (data: {
        currentPassword: string;
        newPassword: string;
    }) => api.put("/User/ChangePassword", data);
    refreshToken = async (data: { Token: string; RefreshToken: string }) => {
        api.post("/User/RefreshToken", data);
    };
    getUserDetails = async () => {
        return api.get("/User/GetUserDetails");
    };
    editUserProfile = async (data: {
        firstName: string;
        lastName: string;
        organization: string;
        email: string;
    }) => {
        return api.put("/User/EditProfile", data);
    };
}

export default new AuthService();
