const Constant = {
    token: "token",
    refreshToken: 'refreshToken',
    defaultErrorMessage: "something went wrong",
    REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
    appId: 'appId',
    platform: 'platform',
    IOS: 'iOS',
    IOSvalue: 'IOS',
    Android: 'Android',
    apk: "apk",
    ipa: "ipa",
    MonthDayYear: 'MM/DD/YYYY',
    DayMonthYear: 'DD-MM-YYYY',
    DateFormatWithHours: 'Do MMMM YYYY, h:mm a',
    deleteProjectConfirm: "This action can't be undone, are you sure want to delete the project?",
    removeAccessConfirm: "Are you sure want to remove the user access ?",
    deleteProjectMessage: "successfully deleted the project",
    leaveProjectSuccess: "Successfully removed the project",
    passwordChangeSuccess: "Password Changed Successfully",
    uploadInProgress: 'Uploading is in progress, this may take a while!',
    uploadSuccess: 'Successfully Uploaded The file',
    uploadFailed: 'Error while uploading the bundle file',
    deleteAppVersionConfirm: "Are you sure you want to delete App Version ",
    deleteAppVersionSuccess: "Successfully Deleted The App Version",
    deleteAppConfirm: "Are you sure you want to delete App ?",
    deleteAppSuccess: "Succesfully Deleted The App",
    loginError: "Sign in Failed, something went wrong",
    logoutConfirm: 'Are you sure want to logout from the application?',
    invalidCred: "Please add valid mail id and Password",
    emptyFieldError: "This field can't be empty",
    invalidEmail: "Invalid Email Address",
    invalidPassword: "Password should be atleast 4 characters",
    invalidMatch: "Passwords do not match",
    redirectToLogin: "Please Login to continue",
    removeUserError: "Couldn't remove the user",
    changeAccessError: "Couldn't change the Access",
    copyToClipBoardSuccess: "Saved To Clipboard",
    profileUpdateSuccess: 'Profile Updated Succesffully',
    publishSuccessfull: 'Successfully published the version',
    unPublishSuccessfull: 'Successfully unPublished the version',
    emailSentConfirm: 'Reset Email sent successffully, please check your email',
    shareProjectSuccess: "Successfully shared the project",
    error: "error",
    success: "success",
    twoSeconds: 2000,
    disabledEditing: "Edit Disabled",
    deleteVersion: 'Delete Version',
    deleteProject: 'Delete Project',
    removeAccess: 'Remove Access',
    leaveProject: 'Leave Project',
    leaveProjectConfirm: 'Are you sure you want to leave this project ?',
    deleteApp: 'Delete App',
    logOut: 'Logout',
    delete: "Delete",
    remove: "Remove",
    leave: "Leave",
    viewer: 'Viewer',
    editor: 'Editor',
    fileLimitExceeded: 'File Limit Exceeded, only 1 file can be uploaded at a time',
}

export default Constant;