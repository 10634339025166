import * as React from 'react';
import { Button, TextField, Box, Grid, Typography, Container, CircularProgress } from '@mui/material';
import RegisterImage from '../../assets/images/img_register.png';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import authStore from '../../Store/authStore';
import Routes from '../../Global/Routes';

import HFHeader from '../../common/Header';
import HFFooter from '../../common/Footer';
import { useEffect, useRef, useState } from 'react';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import authService from '../../Services/authService';
import { NotificationRefType } from '../../Global/Interfaces';
import NotificationService from '../../Components/Notification/Notification';
import LoaderIcon from '../../Components/Loader/LoderIcon';

function RegisterUser() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const notificaitonRef = useRef<NotificationRefType>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const emailFromParams = searchParams.get('email');
    const [formData, setFormData] = useState<any>({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        password: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        password: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if (emailFromParams) {
            const form = structuredClone(formData);
            setFormData({
                ...form,
                email: emailFromParams
            })
        }
    }, [])


    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));
    };

    const validateField = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'firstName':
                return value.length < 1 ? Constant.emptyFieldError : '';
            case 'lastName':
                return value.length < 1 ? Constant.emptyFieldError : '';
            case 'email':
                return Utility.isValidEmail(value) ? '' : Constant.invalidEmail;
            case 'organization':
                return value.length < 1 ? Constant.emptyFieldError : '';
            case 'password':
                return value.length < 4 ? Constant.invalidPassword : '';
            case 'confirmPassword':
                return value !== formData.password ? Constant.invalidMatch : '';
            default:
                return '';
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const newErrors: any = {};
            Object.keys(formData).forEach((fieldName: any) => {
                newErrors[fieldName] = validateField(fieldName, formData[fieldName]);
            });
            setErrors(newErrors);

            if (Object.values(newErrors).every((error) => error === '')) {
                setLoading(true);
                await authService.registerUser(formData);
                navigate(
                    Routes.login,
                    {
                        state: {
                            fromRegistration: true
                        }
                    });

                setLoading(false);
            }
        }
        catch (error: any) {
            notificaitonRef.current?.showNotification(Utility.getErrorMessage(error), "error");
            setLoading(false);
        }
    };


    const checkError = (field: any) => {
        return field ? true : false;
    }

    return (

        <Box component="main"
            sx={{
                height: '100%',
                width: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }}>
            <HFHeader />
            <Box
                sx={{
                    height: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <Container
                    sx={{
                        mb: 2,
                        p: 0
                    }}
                    maxWidth="lg"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={5} sx={{ display: 'flex', justifyContent: 'center', w: '100%' }}>
                            <Container
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    maxWidth: '300px',
                                    mx: 0,
                                    px: 0
                                }}
                            >
                                <Typography component="h1" variant="h5" sx={{
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                }}>
                                    Create an account
                                </Typography>
                                <Box component="form" sx={{ mt: 1 }}>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        name="firstName"
                                        autoFocus
                                        onChange={handleChange}
                                        helperText={errors.firstName}
                                        error={checkError(errors.firstName)}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="lastName"
                                        label="Last Name"
                                        id="lastName"
                                        onChange={handleChange}
                                        helperText={errors.lastName}
                                        error={checkError(errors.lastName)}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        disabled={emailFromParams ? true : false}
                                        defaultValue={emailFromParams}
                                        autoComplete="off"
                                        onChange={handleChange}
                                        helperText={errors.email}
                                        error={checkError(errors.email)}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="organization"
                                        label="Organization Name"
                                        id="organizaitonName"
                                        inputProps={{
                                            autocomplete: 'off',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                        onChange={handleChange}
                                        helperText={errors.organization}
                                        error={checkError(errors.organization)}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        onChange={handleChange}
                                        helperText={errors.password}
                                        error={checkError(errors.password)}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        onChange={handleChange}
                                        helperText={errors.confirmPassword}
                                        error={checkError(errors.confirmPassword)}
                                    />
                                    <Button
                                        size="large"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mb: 2, mt: 1 }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }}
                                    >
                                        {loading ? <LoaderIcon /> : "Register"}
                                    </Button>
                                </Box>
                            </Container>
                        </Grid>
                        <Grid item
                            sm={4}
                            md={7}
                            sx={{
                                display: { xs: 'none', sm: 'none', md: 'block' },
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'right'
                            }}>
                            <img src={RegisterImage} alt="Image Description" />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <HFFooter />
            <NotificationService ref={notificaitonRef} />
        </Box>
    );
}
export default RegisterUser;
