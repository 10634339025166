import React from "react";
import { Route, Navigate } from "react-router-dom";
import Routes from "../Global/Routes";
import authStore from "../Store/authStore";

const PrivateRoute = ({ component: Component }: any) => {
  return authStore.getCurrentUser() != null ? (
    <Component />
  ) : (
    <Navigate to={Routes.login} />
  )
};

export default PrivateRoute;
