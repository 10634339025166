import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import LoginImage from '../../assets/images/img_login.png';

const PageNotFound = () => {
    return (
        <Box
            component="main"
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }}>

            <Box
                sx={{
                    height: '100%',
                    overflow: 'auto',
                    flex: '1 1 auto',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Container
                    sx={{
                        mb: 2,
                        p: 0
                    }}
                    maxWidth="lg"
                >
                    <Grid container spacing={2} flexDirection={'column'} alignContent={'center'} alignItems={'center'}>
                        <Typography variant='fontBoldLarge' fontSize={'30px'}>Page Not Found!</Typography>
                        <Grid item
                            sm={4}
                            md={7}
                            sx={{
                                display: { xs: 'none', sm: 'none', md: 'block' },
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <img height={'200px'} width={'200px'} src={LoginImage} alt="Hotfix" />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>

    );
}

export default PageNotFound;