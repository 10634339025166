import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Grid, Modal, Typography, Button, Box, Stack, Paper, Select, FormControl, MenuItem, SelectChangeEvent, SvgIcon, Chip, Avatar, Input, Autocomplete, TextField, InputAdornment, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useStyles } from '../../common/theme';
import { Theme, useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { RemoveIcon, UserIcon, NextIcon } from '../../assets/images/SvgImages'
import PeopleAccessModal from './PeopleWithAccess';
import { ProjectDetails, ShareProjectType, ShareProjectForm, AccessType, NotificationRefType } from '../../Global/Interfaces';
import projectService from '../../Services/projectService';
import { makeStyles } from '@mui/styles';
import Spinner from '../Loader/Spinner';
import NotificationService from '../Notification/Notification';
import Utility from '../../Global/Utility';
import SearchPopover from './SearchPopover';
import Constant from '../../Global/Constant';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#E0E0E0',
    borderRadius: 8,
    color: theme.palette.text.primary,
    padding: 4,
    alignItems: 'center',
    boxShadow: 'none',
    display: 'flex',
    position: 'relative'
}));



type ShareProjectProps = {
    handleShareProjectClose: () => any;
    shareProjectopen: boolean;
    selectedProject?: ProjectDetails
}
const formDefaults: ShareProjectForm = {
    emails: [],
    accessType: "Viewer"
}

export default function ShareProjectModal(props: ShareProjectProps) {
    const { handleShareProjectClose, shareProjectopen, selectedProject } = props;
    const classes = useStyles();
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);
    const [sharedProject, setSharedProject] = useState<ShareProjectType>()
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<ShareProjectForm>(formDefaults)
    const [email, setEmail] = useState('');
    const notificationRef = useRef<NotificationRefType>(null);
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        getSharedProjectDetails();
    }, [])


    const shareProject = async () => {
        try {
            if (!selectedProject?.projectId) return;
            setLoading(true);
            const payload = { projectId: selectedProject.projectId, ...formData };
            await projectService.shareProject(payload);
            getSharedProjectDetails();
            setEmail('');
            setFormData(formDefaults);
            setLoading(false);
            notificationRef.current?.showNotification(Constant.shareProjectSuccess, "success");
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
            setLoading(false);
        }
    }


    const getSharedProjectDetails = async () => {
        try {
            setLoading(true);
            if (!selectedProject?.projectId) return;
            const result = await projectService.getSharedProjectDetails(selectedProject.projectId)
            setSharedProject(result.data);
            setLoading(false);
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
            setLoading(false);
        }
    }
    const handleAccessChange = (event: SelectChangeEvent) => {
        setFormData({ ...formData, accessType: event.target.value as AccessType });
    };

    const focusOnInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const [peopleAccessOpen, setPeopleAccessOpen] = React.useState(false);
    const handleWithAccessOpen = () => setPeopleAccessOpen(true);

    const handleEmailSelect = (email: string) => {
        const emailsCopy = formData.emails;
        setFormData({
            ...formData,
            emails: [...emailsCopy, email]
        })
        setEmail("");
    };

    const removeMail = (emailId: string) => {
        const emails = formData.emails;
        const newEmails = emails.filter((email) => {
            return email !== emailId
        })
        setFormData({
            ...formData,
            emails: [...newEmails]
        })
    }

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value)
    }

    const userAccessCount = () => {
        const emailCount = sharedProject?.sharedUsers.length;
        return emailCount ? `and ${emailCount} others have access` : '';
    }

    const checkIfExternalUser = (emailId: string) => {
        return !sharedProject?.emailList.includes(emailId);
    }

    const shareProjectContent = () => {
        return <Grid direction={'column'} container gap={'16px'}>
            <Grid item>
                <Typography variant='fontBoldLarge'>Share Projects</Typography >
                <IconButton
                    aria-label="close"
                    onClick={handleShareProjectClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    }}
                >
                    <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
                </IconButton>
            </Grid>
            <Grid item >
                <Typography variant="caption" display="block" gutterBottom>
                    People with access
                </Typography>
                <Stack spacing={2}>
                    <Item>
                        <SvgIcon sx={{ fontSize: 24 }}>{UserIcon}</SvgIcon>
                        <Typography variant="subtitle1" marginLeft={1}>You {userAccessCount()}</Typography>
                        <IconButton sx={{ position: 'absolute', right: 4, cursor: 'pointer' }} size='small' onClick={handleWithAccessOpen}>
                            <SvgIcon sx={{ fontSize: 24 }}>{NextIcon}</SvgIcon>
                        </IconButton>
                    </Item>
                </Stack>
            </Grid>
            <Grid item>
                <FormControl sx={{ width: '100%' }} size="small">
                    <Box
                        sx={{
                            width: '100%',
                            height: 'max-content',
                            borderRadius: 1,
                            border: `1px solid ${theme.palette.primary.main}`,
                            padding: '5px',
                            cursor: 'text'
                        }}
                        onClick={focusOnInput}
                    >
                        <Grid
                            container
                            width='100%'
                            gap={1}
                        >
                            {
                                formData.emails.map(emailId =>
                                    <Grid item>

                                        <Tooltip
                                            title={
                                                checkIfExternalUser(emailId) ? "External User" : "Existing User"
                                            }
                                            sx={{
                                                cursor: 'default'
                                            }}
                                        >
                                            <Chip
                                                label={emailId}
                                                variant="outlined"
                                                avatar={
                                                    <Avatar
                                                        sx={{
                                                            backgroundColor:
                                                                checkIfExternalUser(emailId) ? 'red' : `${theme?.palette?.primary?.main}`,
                                                        }}
                                                    >
                                                        <Typography variant='fontPrimary' sx={{ color: 'white' }}>
                                                            {emailId.toUpperCase()[0]}
                                                        </Typography>
                                                    </Avatar>
                                                }
                                                onDelete={() => {
                                                    removeMail(emailId)
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <SearchPopover
                            email={email}
                            emailList={sharedProject?.emailList}
                            inputRef={inputRef}
                            handleEmailChange={handleEmailChange}
                            handleEmailSelect={handleEmailSelect}
                            showList={showList}
                            setShowList={setShowList}
                        />
                    </Box>
                </FormControl>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControl sx={{ width: 120 }} size="small">
                    <Select
                        value={formData.accessType}
                        onChange={handleAccessChange}
                        displayEmpty
                    >
                        <MenuItem value={"Editor"}>Editor</MenuItem>
                        <MenuItem value={"Viewer"}>Viewer</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant='contained'
                    size='small'
                    onClick={shareProject}
                    disabled={formData.emails.length === 0}
                >
                    <Typography variant='fontBold' color='white'>Share</Typography>
                </Button>
            </Grid>
        </Grid>
    }

    return (
        <div>
            <Modal
                open={shareProjectopen}
                onClose={handleShareProjectClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClick={() => setShowList(false)}
            >
                <>
                    <Box style={{ width: 600 }} className={classes.modalBox}>
                        <Spinner loading={loading} />
                        {shareProjectContent()}
                    </Box>
                    <NotificationService ref={notificationRef} />
                </>
            </Modal>
            <PeopleAccessModal
                peopleAccessOpen={peopleAccessOpen}
                setPeopleAccessOpen={setPeopleAccessOpen}
                sharedUsers={sharedProject?.sharedUsers}
                selectedProject={selectedProject}
                getSharedProjectDetails={getSharedProjectDetails}
            />
        </div >
    );
}