import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import { Grid, IconButton, Paper, Typography, Box, Button, Tooltip } from '@mui/material';
import AddAppModal from '../../Components/Modal/AddAppModal';
import CreateProjectModal from '../../Components/Modal/CreateProjectModal';
import ProjectTable from './ProjectTable';
import projectService from '../../Services/projectService';
import { AddNewApp, DeleteModalRefType, NotificationRefType, ProjectDetails, ProjectType, TokenType } from '../../Global/Interfaces';
import Spinner from '../../Components/Loader/Spinner';
import NotificationService from '../../Components/Notification/Notification';
import ConfirmModal from '../../Components/Modal/ConfirmModal';
import ProjectOptions from './ProjectOptions';
import { AddCircle } from '@mui/icons-material';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import NodataImage from '../../assets/images/img_empty.png';
import Utility from '../../Global/Utility';

function ProjectPage() {
    const [openSidebar, setOpenSidebar] = useState(true);
    const [openAddApp, setOpenAddApp] = useState(false);
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [projects, setProjects] = useState<ProjectType>();
    const [selectedProject, setSelectedProject] = useState<ProjectDetails>();
    const [loading, setLoading] = useState(false);
    const [editProject, setEditProject] = useState(false);
    const notificationRef = useRef<NotificationRefType>(null);
    const deleteProjectConfirmRef = useRef<DeleteModalRefType>(null);
    const leaveProjectConfirmRef = useRef<DeleteModalRefType>(null);
    const userInfo: TokenType | null = authStore.getCurrentUser();

    const getAllProjects = async () => {
        try {
            setLoading(true);
            if (userInfo?.email) {
                const projectResponse = await projectService.getAllProjects(userInfo.email);
                const projectData: ProjectType = projectResponse.data;
                setProjects({ ...projectData });
            }
            setLoading(false);
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Constant.defaultErrorMessage, "error");
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllProjects();
    }, [])


    const handleAddAppOpen = () => setOpenAddApp(true);
    const handleAddAppClose = () => setOpenAddApp(false);

    const handleCreateProjectOpen = (edit: boolean) => {
        setEditProject(edit);
        setOpenCreateProject(true);
    }
    const handleCreateProjectClose = () => setOpenCreateProject(false);


    const EmptyProjects = () => {
        return <Grid container height={'100%'}>
            <Grid item width={300}></Grid>
            <Grid
                item
                xs
                height={'100%'}
            >
                <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    height={'100%'}
                >
                    <Grid item>
                        <img src={NodataImage} alt="NoData" />
                    </Grid>
                    <Grid item>
                        <Typography pt={6}>No projects, please create new </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3 }}
                            onClick={() => handleCreateProjectOpen(false)}
                        >
                            Create Project
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }

    const handleDeleteProject = async () => {
        deleteProjectConfirmRef.current?.openDeleteModal(
            Constant.deleteProject,
            Constant.deleteProjectConfirm,
            Constant.delete, null
        );
    }

    const handleDeleteProjectConfirm = async () => {
        try {
            if (selectedProject) {
                await projectService.deleteProject({ projectId: selectedProject.projectId })
                await getAllProjects();
                notificationRef.current?.showNotification(Constant.deleteProjectMessage, "success", Constant.twoSeconds);
            }
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
        }
    }

    const handleLeaveProject = async () => {
        leaveProjectConfirmRef.current?.openDeleteModal(
            Constant.leaveProject,
            Constant.leaveProjectConfirm,
            Constant.leave, null
        );
    }

    const handleLeaveProjectConfirm = async () => {
        try {
            if (selectedProject && userInfo?.email) {
                const projectPayload = { projectId: selectedProject.projectId, emailId: userInfo?.email };
                await projectService.leaveProject(projectPayload);
                await getAllProjects();
                notificationRef.current?.showNotification(Constant.leaveProjectSuccess, "success", Constant.twoSeconds);
            }
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
        }
    }

    const saveProject = async (projectName: string) => {
        try {
            setLoading(true);
            if (!userInfo || !userInfo.email) {
                throw Error(Constant.defaultErrorMessage)
            }
            if (editProject) {
                if (!selectedProject?.projectId) {
                    throw Error(Constant.defaultErrorMessage)
                }
                const renameProjectPayload = {
                    projectId: selectedProject.projectId,
                    projectName,
                    emailId: userInfo?.email
                }
                await projectService.renameProject(renameProjectPayload);
                handleCreateProjectClose();
                await getAllProjects();
            }
            else {
                const createProjectPayLoad = { projectName, emailId: userInfo?.email }
                await projectService.createNewProject(createProjectPayLoad);
                handleCreateProjectClose();
                await getAllProjects();
            }
            setLoading(false);
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error", null);
        }
    }


    const addNewApp = async (newApp: AddNewApp) => {
        setLoading(true);
        try {
            if (selectedProject && userInfo) {
                const payload = {
                    appType: newApp.appType,
                    bundleId: newApp.bundleId,
                    projectId: selectedProject.projectId,
                    emailId: userInfo?.email,
                    appName: newApp.appName
                };
                await projectService.addNewApp(payload);
                setSelectedProject({ ...selectedProject });
                handleAddAppClose();
            }
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
        }
        setLoading(false);
    }

    const isViewer = () => {
        return selectedProject?.accessType === Constant.viewer
    }

    const isEditor = () => {
        return selectedProject?.accessType === Constant.editor
    }

    const ProjectTableContent = () => {
        return <Grid container position={'relative'} zIndex={5}>
            <Grid item width={300}></Grid>
            <Grid item flex={'1 1 auto'} padding={2}>
                <Grid
                    item
                    container
                    alignItems={'center'}
                    marginBottom={'20px'}
                >
                    <Grid item xs={6}>
                        <ProjectOptions
                            openProjectModal={handleCreateProjectOpen}
                            deleteProject={handleDeleteProject}
                            leaveProject={handleLeaveProject}
                            selectedProject={selectedProject}
                            shared={selectedProject?.shared}
                            getAllProjects={getAllProjects}
                        />
                    </Grid>
                    <Grid item sx={{ marginLeft: 'auto', marginRight: '10px' }}>
                        <Grid container alignItems={'center'} justifyContent={'center'}>
                            <Typography variant='fontBold' >Add App</Typography>
                            <Tooltip title={isViewer() ? Constant.disabledEditing : ""}>
                                <span>
                                    <IconButton
                                        disabled={isViewer()}
                                        onClick={handleAddAppOpen} size='small'>
                                        <AddCircle />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ boxShadow: 'none', bgcolor: 'transparent', overflow: 'scroll' }}>
                        <ProjectTable
                            getAllProjects={getAllProjects}
                            selectedProject={selectedProject}
                            handleAddAppOpen={handleAddAppOpen}
                            projectsLoading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    }

    return (
        <>
            <Box
                component='main'
                sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Header setSelectedProject={setSelectedProject} open={openSidebar} setOpen={setOpenSidebar} projects={projects} />
                <Sidebar
                    open={openSidebar}
                    setOpen={setOpenSidebar}
                    projects={projects}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    openProjectModal={handleCreateProjectOpen}
                />
                <Box
                    sx={{
                        height: '100%',
                        overflow: 'auto',
                        flex: '1 1 auto',
                        position: 'relative'
                    }}
                >
                    {
                        !loading ?
                            (selectedProject?.projectId ? ProjectTableContent() : EmptyProjects())
                            : <Spinner loading={loading} />
                    }
                </Box>
            </Box>
            <NotificationService ref={notificationRef} />
            <ConfirmModal handleConfirm={handleDeleteProjectConfirm} ref={deleteProjectConfirmRef} />
            <ConfirmModal handleConfirm={handleLeaveProjectConfirm} ref={leaveProjectConfirmRef} />
            <AddAppModal
                open={openAddApp}
                handleClose={handleAddAppClose}
                addNewApp={addNewApp}
            />
            <CreateProjectModal
                open={openCreateProject}
                handleClose={handleCreateProjectClose}
                saveProject={saveProject}
                rename={editProject}
            />
        </>
    );
}
export default ProjectPage;
