import React from 'react';
import { BrowserRouter } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import customTheme from './common/theme';
import ApplicationRoutes from "./Routing/ApplicationRoutes"
// TODO remove, this demo shouldn't need to reset the theme.

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <BrowserRouter>
        <ApplicationRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
