import { Container, Grid, Typography, Link, Box } from '@mui/material';
import * as React from 'react';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '} {' '}
            {new Date().getFullYear()}
            {'.'}
            <Link color="inherit" href="#" underline='hover'>  Hotfix. All rights reserved
            </Link>
        </Typography>
    );
}

function HFFooter() {
    return (
        <Box
            component="footer"
            sx={{
                py: 1,
                px: 2,
                m: 0
            }}
        >
            <Container maxWidth={false} sx={{  display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between' }}>
                <Copyright />
                <Typography variant="body2" color="text.secondary" gutterBottom sx={{display: 'flex', alignItems: 'center' }}>
                    Terms of Use | Privacy Policy
                </Typography>
            </Container>
        </Box>
    );
}
export default HFFooter;