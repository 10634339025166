import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Header from '../../Components/Header/Header';
import { DropzoneArea } from 'material-ui-dropzone';
import { Button, CircularProgress, Grid, ListItemText, Menu, MenuItem, SvgIcon, Tooltip, Typography, styled, useTheme } from '@mui/material';
import AppTable from './AppTable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import projectService from '../../Services/projectService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppDetails, NotificationRefType, ProjectType, TokenType } from '../../Global/Interfaces';
import NotificationService from '../../Components/Notification/Notification';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import { IosSvg, AndroidSvg } from "../../assets/images/SvgImages";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DeleteModalRefType } from '../../Global/Interfaces';
import ConfirmModal from '../../Components/Modal/ConfirmModal';
import { useStyles } from '../../common/theme';
import authStore from '../../Store/authStore';
import Utility from '../../Global/Utility';
import Spinner from '../../Components/Loader/Spinner';

const BodyWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "330px"
    }),
}));

type AppOptionsProps = {
    deleteApp: () => any,
}

function AppVersion() {
    const [openSidebar, setOpenSidebar] = useState(false);
    const [appDetails, setAppDetails] = useState<AppDetails>();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const notificationRef = useRef<NotificationRefType>(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const theme: any = useTheme();
    const deleteAppConfirmRef = useRef<DeleteModalRefType>(null);
    const [resetDropZone, setResetDropZone] = useState(0);
    const [projects, setProjects] = useState<ProjectType>();
    const userInfo: TokenType | null = authStore.getCurrentUser();

    const goToProject = () => {
        navigate(Routes.projectDefault, { state: { projectId: appDetails?.projectId } });
    }


    const getAllProjects = async () => {
        try {
            if (userInfo?.email) {
                const projectResponse = await projectService.getAllProjects(userInfo.email);
                const projectData: ProjectType = projectResponse.data;
                setProjects({ ...projectData });
            }
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Constant.defaultErrorMessage, "error");
        }
    }

    const getParamDetails = () => {
        const appId = searchParams.get(Constant.appId);
        const platform = searchParams.get(Constant.platform);
        return { appId, platform };
    }

    useEffect(() => {
        getAllProjects();
    }, [])

    const getAppDetails = async (loaderNotRequired?: boolean) => {
        try {
            const { appId, platform } = getParamDetails();
            if (appId && platform) {
                if (!loaderNotRequired) setLoading(true);
                const appDetailsResponse = await projectService.getAppDetails({ appId, platform });
                setAppDetails(appDetailsResponse?.data[0]);
            }
            if (!loaderNotRequired) setLoading(false);
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
            if (!loaderNotRequired) setLoading(false);
        }
    }

    const isViewer = () => {
        return appDetails?.accessType === Constant.viewer
    }

    const isEditor = () => {
        return appDetails?.accessType === Constant.editor
    }



    useEffect(() => {
        getAppDetails();
    }, [])

    const handleBundleFileUpload = async (file: File[]) => {
        try {
            if (!file || !appDetails?.appType || !appDetails.appId) return;
            notificationRef.current?.showNotification(Constant.uploadInProgress, "info", Constant.twoSeconds);
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file[0]);
            formData.append('platform', appDetails?.appType);
            formData.append('appTableId', appDetails?.appId?.toString());
            await projectService.uploadBundleFile(formData)
            await getAppDetails();
            setUploading(false);
            notificationRef.current?.showNotification(Constant.uploadSuccess, "success", Constant.twoSeconds);
            setResetDropZone((prev) => prev + 1);
        } catch (error: any) {
            notificationRef.current?.showNotification(Constant.uploadFailed, "error");
            setUploading(false);

            setResetDropZone((prev) => prev + 1);
        }
    };

    const getFileType = () => {
        return appDetails?.appType === Constant.Android ? [".apk", ".aab"] : [".ipa"]
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const copyToClipboard = async (text: string) => {
        await navigator.clipboard.writeText(text);
        notificationRef.current?.showNotification(Constant.copyToClipBoardSuccess, "success", Constant.twoSeconds)
    }

    const handleDeleteApp = async () => {
        const { appId, platform } = getParamDetails();
        deleteAppConfirmRef.current?.openDeleteModal(
            Constant.deleteApp,
            Constant.deleteAppConfirm,
            Constant.delete,
            { appTableId: appId, platform }
        );
    }

    const updateAppDetails = (app: AppDetails) => {
        setAppDetails(app);
    }

    const handleDeleteConfirm = async ({ appTableId, platform }: any) => {
        try {
            await projectService.deleteApp({ appTableId, platform })
            // notificationRef.current?.showNotification(
            //     Constant.deleteAppSuccess,
            //     "success",
            //     Constant.twoSeconds
            // )
            goToProject();
        }
        catch (error: any) {
            notificationRef.current?.showNotification(
                error?.message || Constant.defaultErrorMessage,
                "error"
            );
        }
    }

    const menuListItems = () => {
        return (
            <>
                <MenuItem disabled={isViewer() || isEditor()} onClick={handleDeleteApp}>
                    <ListItemText>Delete App</ListItemText>
                </MenuItem>
            </>
        )
    }

    const classes = useStyles();

    const handleAlert = (message: string, alertType: "info" | "error" | "success") => {
        notificationRef.current?.showNotification(message, alertType);
        setResetDropZone((prev) => prev + 1);
    }


    return (
        <>
            <Grid container>
                <Header
                    open={openSidebar}
                    setOpen={setOpenSidebar}
                    projects={projects}
                />
                <BodyWrapper open={openSidebar}>
                    <Grid container alignContent={'center'} alignItems={'center'}>
                        <Grid item>
                            <Button
                                sx={{ color: 'black' }}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                startIcon={
                                    appDetails?.appType === Constant.IOSvalue ?
                                        <SvgIcon sx={{ fontSize: 20 }}>{IosSvg}</SvgIcon> :
                                        <SvgIcon sx={{ fontSize: 20 }}>{AndroidSvg}</SvgIcon>
                                }>
                                {appDetails?.appName}<ArrowDropDownIcon />
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    '& .MuiPaper-root': {
                                        borderRadius: '10px'
                                    }
                                }}
                            >
                                {menuListItems()}
                            </Menu>
                        </Grid>
                        <Grid item justifySelf={'center'} alignSelf={'center'} marginLeft={'auto'}>
                            <Tooltip title='Copy Access Key'>
                                <Button
                                    onClick={() => copyToClipboard(appDetails?.accessKey || "")}
                                >
                                    <Typography
                                        variant='fontPrimary'
                                        color={theme?.palette?.secondary?.main}
                                        marginRight={1}
                                    >
                                        {appDetails?.accessKey}
                                    </Typography>
                                    <ContentCopyIcon fontSize='small' sx={{ color: theme.palette.secondary.main }} />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid width={'100%'} container alignItems={'center'} justifyContent={'center'} xs={12} paddingTop={1}>
                        {/* <InputFileUpload handleUpload={handleBundleFileUpload} fileType={getFileType()} loading={uploading} /> */}
                        {
                            !isViewer() &&
                            <DropzoneArea
                                dropzoneClass={classes.DragnDrop}
                                acceptedFiles={getFileType()}
                                onDrop={handleBundleFileUpload}
                                maxFileSize={1000000000}
                                filesLimit={1}
                                dropzoneText={uploading ? 'Uploading ...' : "Drag and drop a file here or click"}
                                // onAlert={handleAlert}
                                showAlerts={false}
                                key={resetDropZone}
                            />
                        }
                        {
                            isViewer() &&
                            <Tooltip title={Constant.disabledEditing}>
                                <div className={classes.DragnDrop} style={{ color: theme.palette.secondary.main }}>
                                    Drag and drop a file here or click
                                </div>
                            </Tooltip>
                        }

                    </Grid>
                    <Grid marginTop={2} item xs={12}>
                        <AppTable updateAppDetails={updateAppDetails} appDetails={appDetails} getAppDetails={getAppDetails} />
                    </Grid>
                </BodyWrapper >
                <Spinner loading={loading} />
            </Grid >
            <NotificationService ref={notificationRef} />
            <ConfirmModal ref={deleteAppConfirmRef} handleConfirm={handleDeleteConfirm} />
        </>
    );
}

export default AppVersion;
