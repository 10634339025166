import * as React from 'react';
import { RefObject, useRef, useState } from 'react';
import { Grid, Modal, Typography, Box, Stack, Paper, Select, FormControl, MenuItem, SelectChangeEvent, SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useStyles } from '../../common/theme';
import { styled } from '@mui/material/styles';
import { RemoveIcon, BackIcon, UserIcon } from '../../assets/images/SvgImages'
import { DeleteModalRefType, NotificationRefType, ProjectDetails, SharedUser } from '../../Global/Interfaces';
import projectService from '../../Services/projectService';
import Constant from '../../Global/Constant';
import ConfirmModal from './ConfirmModal';
import NotificationService from '../Notification/Notification';
import EmptyImage from "../../assets/images/img_register.png";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#E0E0E0',
    borderRadius: 8,
    color: theme.palette.text.primary,
    padding: 4,
    alignItems: 'center',
    boxShadow: 'none',
    display: 'flex',
    position: 'relative'
}));

type PeopleModelType = {
    sharedUsers?: SharedUser[],
    setPeopleAccessOpen: any,
    peopleAccessOpen: any,
    selectedProject?: ProjectDetails,
    getSharedProjectDetails: () => any
}

export default function PeopleAccessModal({
    sharedUsers,
    setPeopleAccessOpen,
    peopleAccessOpen,
    selectedProject,
    getSharedProjectDetails
}: PeopleModelType) {
    const classes = useStyles();
    const removeAccessConfirmRef = useRef<DeleteModalRefType>(null);
    const notificationRef = useRef<NotificationRefType>(null);
    const peopleAccessClose = () => setPeopleAccessOpen(false);

    const handleAccessChange = async (event: SelectChangeEvent, emailId: string) => {
        const accessType = event.target.value as string;
        if (!selectedProject?.projectId) return;
        try {
            await projectService.changeAccess({
                projectId: selectedProject?.projectId,
                emailId,
                accessType
            })
            await getSharedProjectDetails();
        }
        catch (error: any) {
            notificationRef.current?.showNotification(Constant.changeAccessError, "error");
        }

    };

    const handleRemoveAccessConfirm = async (email: string) => {
        removeAccessConfirmRef.current?.openDeleteModal(
            Constant.removeAccess,
            Constant.removeAccessConfirm,
            Constant.remove,
            { email }
        );
    }


    const removeUserAccess = async ({ email }: { email: string }) => {
        if (!selectedProject?.projectId) return;
        try {
            await projectService.removeAccess({
                projectId: selectedProject?.projectId,
                emailId: email
            })
            await getSharedProjectDetails();
        }
        catch (error) {
            notificationRef.current?.showNotification(Constant.removeUserError, "error");
        }
    }

    return (
        <div>
            <Modal
                open={peopleAccessOpen}
                onClose={peopleAccessClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ width: 600, minHeight: 300 }} className={classes.modalBox}>
                    <Grid direction={'column'} container gap={'16px'}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                aria-label="close"
                                onClick={peopleAccessClose}
                            >
                                <SvgIcon sx={{ fontSize: 24 }}>{BackIcon}</SvgIcon>
                            </IconButton>
                            <Typography variant='fontBoldLarge' marginLeft={2}>People With Access</Typography >
                            <IconButton
                                aria-label="close"
                                onClick={peopleAccessClose}
                                sx={{
                                    position: 'absolute',
                                    right: 14,
                                    top: 16,
                                }}
                            >
                                <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
                            </IconButton>
                        </Grid>
                        <Grid
                            container
                            padding={1}
                            direction={'row'}
                            gap={1}
                            overflow={'auto'}
                            maxHeight={300}
                        >

                            {
                                !sharedUsers?.length &&
                                <Box sx={{ placeItems: 'center', display: 'grid' }} height={'10rem'} width={'100%'}>
                                    <img
                                        src={EmptyImage}
                                        alt='empty'
                                        height={'100px'}
                                        width={'100px'}
                                    />
                                    <Typography textAlign={'center'} variant='fontBoldLarge' color={'secondary'}>No Shared Users</Typography>
                                </Box>
                            }
                            {
                                sharedUsers?.map(user => {
                                    return <Grid item xl display={'flex'}>
                                        <Stack spacing={2} flex={'1 1 auto'} marginRight={2} >
                                            <Item>
                                                <SvgIcon sx={{ fontSize: 24 }}>{UserIcon}</SvgIcon>
                                                <Typography
                                                    variant="subtitle1"
                                                    marginLeft={1}
                                                    maxWidth={'300px'}
                                                    minWidth={'300px'}
                                                    overflow={'clip'}
                                                    textOverflow={'ellipsis'}
                                                >
                                                    {user.emailId}
                                                </Typography>
                                                <IconButton
                                                    sx={{ cursor: 'pointer' }}
                                                    size='small'
                                                    onClick={() => handleRemoveAccessConfirm(user.emailId)}
                                                >
                                                    <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
                                                </IconButton>
                                            </Item>
                                        </Stack>
                                        <FormControl sx={{ width: 120 }} size="small">
                                            <Select
                                                defaultValue={user.accessType}
                                                onChange={(e) => handleAccessChange(e, user.emailId)}
                                                displayEmpty
                                            >
                                                <MenuItem value={'Editor'}>Editor</MenuItem>
                                                <MenuItem value={'Viewer'}>Viewer</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <ConfirmModal handleConfirm={removeUserAccess} ref={removeAccessConfirmRef} />
            <NotificationService ref={notificationRef} />
        </div >
    );
}