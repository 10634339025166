import * as React from 'react';
import { useState, useEffect, useRef, Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import projectService from '../../Services/projectService';
import { AppType, DeleteModalRefType, NotificationRefType, ProjectDetails } from '../../Global/Interfaces';
import Spinner from '../../Components/Loader/Spinner';
import NotificationService from '../../Components/Notification/Notification';
import Constant from '../../Global/Constant';
import NodataImage from '../../assets/images/img_empty.png';
import Utility from '../../Global/Utility';

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'black',
        fontWeight: 700,
        fontSize: '14px',
        height: '44px',
        padding: 0,
        paddingLeft: '14px',
        borderBottom: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "0px",
        paddingLeft: '14px',
        height: '44px',
        fontWeight: 400
    },
}));

type ProjectTableProp = {
    selectedProject?: ProjectDetails,
    handleAddAppOpen: () => any,
    getAllProjects: () => any,
    projectsLoading: boolean
}

export default function ProjectTable({ selectedProject, handleAddAppOpen, getAllProjects, projectsLoading }: ProjectTableProp) {
    const navigate = useNavigate();
    const [projectDetails, setProjectDetails] = useState<AppType[]>();
    const [loading, setLoading] = useState(false);
    const notificationRef = useRef<NotificationRefType>(null);

    const getProjectDetails = async () => {
        try {
            if (selectedProject?.projectId) {
                setLoading(true);
                const projectDetailsResponse = await projectService.getProjectDetails(selectedProject.projectId);
                setProjectDetails([...projectDetailsResponse.data]);
                setLoading(false);
            }
        }
        catch (error) {
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
        }
    }

    useEffect(() => {
        getProjectDetails();
    }, [selectedProject])

    const handleClickOnApp = (appId: number, platform: string) => {
        navigate(`/app?appId=${appId}&platform=${platform}`)
    }


    const isDisabled = () => {
        return selectedProject?.accessType === Constant.viewer;
    }

    const EmptyData = () => {
        return <Grid container alignItems={'center'} justifyContent={'center'} p={6} bgcolor={'#F5F5F5'}>
            <Grid item display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <img src={NodataImage} alt="NoData" />
                <Typography pt={6}>Empty project, start adding your apps</Typography>
                <Tooltip title={isDisabled() ? Constant.disabledEditing : ""}>
                    <span>
                        <Button
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3 }}
                            onClick={handleAddAppOpen}
                            disabled={isDisabled()}
                        >
                            Add App
                        </Button>
                    </span>
                </Tooltip>
            </Grid>
        </Grid>
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ width: "100%", position: 'relative', boxShadow: 'none' }}>
                <Table aria-label="simple table" sx={{ boxShadow: 'none' }}>
                    <TableHead>
                        <TableRow
                            sx={{
                                backgroundColor: "rgba(224, 224, 224, 1)"
                            }}
                        >
                            <StyledTableCell sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8, }}>#</StyledTableCell>
                            <StyledTableCell align="left">Bundle ID</StyledTableCell>
                            <StyledTableCell align="left">Platform</StyledTableCell>
                            <StyledTableCell align="left">App Version</StyledTableCell>
                            <StyledTableCell align="left">App Name</StyledTableCell>
                            <StyledTableCell align="left">Hotfix Version</StyledTableCell>
                            <StyledTableCell align="center" sx={{ borderTopRightRadius: 8, borderBottomRightRadius: 8, }}></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectDetails?.map((row, index) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: (index % 2 !== 0) ? "rgba(225, 225, 225, 1)" : "",
                                }}
                            >
                                <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                <StyledTableCell align="left">{row.bundleId}</StyledTableCell>
                                <StyledTableCell align="left">{row.platform}</StyledTableCell>
                                <StyledTableCell align="left">{row.appVersion || "--"}</StyledTableCell>
                                <StyledTableCell align="left">{row.appName || "--"}</StyledTableCell>
                                <StyledTableCell align="left">{row.hotfixNumber || "--"}</StyledTableCell>
                                <StyledTableCell align="center" >
                                    {
                                        <IconButton
                                            size='small'
                                            onClick={() => handleClickOnApp(row.id, row.platform)}
                                        >
                                            <ArrowRightIcon />
                                        </IconButton>
                                    }
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    (loading || projectsLoading) &&
                    <Box>
                        <Spinner noBackground={true} loading={loading} />
                    </Box>
                }
                {
                    (!loading && !projectsLoading && !projectDetails?.length) && EmptyData()
                }
            </TableContainer >
            <NotificationService ref={notificationRef} />
        </>
    );
}