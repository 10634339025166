import Cookies from "js-cookie";
import moment from "moment";
import Constant from "./Constant";

class Utility {
    decodeValue(value: any) {
        const decodedValue = decodeURIComponent(value || "");
        try {
            return JSON.parse(decodedValue);
        } catch (e) {
            return decodedValue;
        }
    }

    parseJwt(token: string) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map((c) => {
                    const base = `00${c.charCodeAt(0).toString(16)}`;
                    return `%${base.slice(-2)}`;
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    }

    range(start: number, end: number) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    delayForTesting = (delayInMili: number) => {
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve(true);
            }, delayInMili);
        });
    };

    disabledFutureDate(current: any) {
        return current && current > moment();
    }

    disabledFutureDateTime = () => {
        const hour = moment().hour();
        const minute = moment().minute();
        const seconds = moment().second();
        return {
            disabledHours: () => {
                if (hour < 23) {
                    let start = hour;
                    if (minute !== 0) {
                        start += 1;
                    }
                    return this.range(start, 24);
                }
                return [];
            },
            disabledMinutes: () => {
                if (hour < 59) {
                    if (moment().hour() === hour) {
                        return this.range(minute + 1, 60);
                    }
                }
                return [];
            },

            disabledSeconds: () => {
                if (moment().second() === seconds) {
                    return this.range(seconds + 1, 60);
                }
                return [];
            },
        };
    };

    trimSpacesInValues(value: string) {
        if (value !== undefined || value !== null) {
            return value.trimLeft();
        }
        return "";
    }

    roundOff(num: number, places: number) {
        const x = 10 ** places;
        return Math.round(num * x) / x;
    }

    getCurrentUser = () => {
        if (Cookies.get(Constant.token)) {
            return this.parseJwt(Cookies.get(Constant.token) as string);
        }
        return null;
    };

    getRefreshTokenTime = () => {
        if (localStorage.refreshToken) {
            const refreshToken = this.parseJwt(localStorage.refreshToken);
            return refreshToken.expiryTime;
        }
        return null;
    };

    convertCreatedDate = (sdate: Date) => {
        const date = new Date(sdate);
        return moment(date).format(Constant.DateFormatWithHours);
    };

    convertToMonthDate = (date: Date) => {
        const newdDate = new Date(date);
        return moment(newdDate).format("MMM D");
    };

    videoDurationConverter = (duration: any) => {
        if (duration !== undefined) {
            const dateObj = new Date(duration);
            const hours = dateObj.getUTCHours();
            const minutes = dateObj.getUTCMinutes();
            const seconds = dateObj.getSeconds();

            return `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        }
    };

    calculateDateDiff = (date: Date) => {
        const localDate = moment(date)
            .local()
            .format(`${Constant.MonthDayYear} `);
        const currentDate = moment()
            .local()
            .format(`${Constant.MonthDayYear} `);
        return moment(localDate, `${Constant.MonthDayYear} `).diff(
            moment(currentDate, `${Constant.MonthDayYear} `),
            "days"
        );
    };

    getErrorMessage = (error: any) => {
        let errorMsg: any = Constant.defaultErrorMessage;
        if (error && error.response && error.response.data) {
            errorMsg = error.response.data;
        }
        return errorMsg;
    };

    calculateTraining = (start: any, end: any) => {
        if (this.calculateDateDiff(start) > 0) {
            return 0;
        }
        if (this.calculateDateDiff(end) < 0) {
            return 0;
        }
        return this.calculateDateDiff(end) + 1;
    };

    calculateTimeCreated = (createdTime: any) => {
        const date = new Date(createdTime);
        return moment(date).fromNow();
    };

    disabledPastDates = (current: any): boolean =>
        current && current < moment().add(-1, "days");

    getFormattedDate = (date: any) => {
        if (date) {
            return moment(date).format(Constant.DayMonthYear);
        }
        return null;
    };

    isValidEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    checkIfLastPage = (total: number, pageSize: number, page: number) =>
        total - pageSize * page == 0 && page > 0;
}

export default new Utility();
