import * as React from 'react';
import { useState } from 'react';
import { Grid, Box, Button, Typography, TextField, Modal, ToggleButton, ToggleButtonGroup, styled, SvgIcon } from '@mui/material';
import Constant from '../../Global/Constant';
import IconButton from '@mui/material/IconButton';
import { useStyles } from '../../common/theme';
import { RemoveIcon } from '../../assets/images/SvgImages'
import { AddNewApp } from '../../Global/Interfaces';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  color: 'black',
  borderRadius: '8px',
  backgroundColor: theme.palette.secondary.main,
  '& .MuiToggleButtonGroup-grouped': {
    margin: '3px',
    border: 0,
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '8px',
      minWidth: '136px',
    },
    '&:first-of-type': {
      borderRadius: '8px',
      minWidth: '136px',
    },
  },
}));



type AddAppModalProps = {
  open: boolean,
  handleClose: () => any,
  addNewApp: (formData: AddNewApp) => any
}

const tabs = {
  tabOne: Constant.IOSvalue,
  tabTwo: Constant.Android
}

export default function AddAppModal({ open, handleClose, addNewApp }: AddAppModalProps) {
  const [appName, setAppName] = useState("");
  const [formData, setFormData] = useState<any>({
    bundleId: "",
    appName: "",
    appType: tabs.tabOne
  })
  const [errors, setErrors] = useState<any>({
    bundleId: "",
    appName: ""
  });

  const classes = useStyles();

  const resetStates = () => {
    setFormData({ appType: tabs.tabOne, appName: "", bundleId: "" });
    setErrors({});
  }

  const handleModalClose = () => {
    handleClose();
    resetStates();
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));


    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const validateField = (fieldName: string, value: string) => {
    switch (fieldName) {
      case 'bundleId':
        return value.length < 1 ? Constant.emptyFieldError : '';
      case 'appName':
        return value.length < 1 ? Constant.emptyFieldError : '';
      default:
        return '';
    }
  }

  const handleTabChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    if (newValue !== "" && newValue !== null)
      setFormData({ ...formData, appType: newValue });
  };

  const checkError = (field: any) => {
    return field ? true : false;
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const newErrors: any = {};
    Object.keys(formData).forEach((fieldName: any) => {
      newErrors[fieldName] = validateField(fieldName, formData[fieldName]);
    });
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === '')) {
      addNewApp(formData);
    }
  };


  return (
    <div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ width: 400 }} className={classes.modalBox}>
          <Grid direction={'column'} gap={'16px'}>
            <Grid item>
              <Typography width={'100%'} variant='fontBoldLarge' >Add App</Typography >
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                }}
              >
                <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
              </IconButton>
            </Grid>
            <Grid item paddingTop={1}>
              <StyledToggleButtonGroup
                size="small"
                value={formData.appType}
                exclusive
                fullWidth
                onChange={handleTabChange}
                aria-label="text alignment"
                sx={{
                  textTransform: 'none',
                  mt: 2
                }}
              >
                <ToggleButton
                  sx={{
                    textTransform: 'none',
                  }}
                  value="IOS"
                  aria-label="left aligned"
                >
                  <Typography variant='fontBold'>iOS</Typography>
                </ToggleButton>
                <ToggleButton
                  sx={{
                    textTransform: 'none',
                  }}
                  value="Android"
                  aria-label="centered"
                >
                  <Typography variant='fontBold'>Android</Typography>
                </ToggleButton>
              </StyledToggleButtonGroup>
              <TextField
                required
                size='small'
                label="Bundle Id"
                name="bundleId"
                fullWidth
                sx={{ mt: 2 }}
                color='primary'
                error={checkError(errors.bundleId)}
                helperText={errors.bundleId}
                autoComplete={undefined}
                onChange={handleChange}
              />
              <TextField
                required
                size='small'
                label="App Name"
                name="appName"
                fullWidth
                sx={{ mt: 2 }}
                color='primary'
                error={checkError(errors.appName)}
                helperText={errors.appName}
                autoComplete={undefined}
                onChange={handleChange}
              />
              <Button variant='contained' size='small'
                sx={{
                  mt: 2
                }}
                fullWidth
                onClick={handleSubmit}
              >
                <Typography variant='fontBold' color={'white'}>Add</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div >
  );
}