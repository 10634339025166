import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppDetails, AppVersion, DeleteModalRefType, NotificationRefType } from '../../Global/Interfaces';
import Utility from '../../Global/Utility';
import projectService from '../../Services/projectService';
import Spinner from '../../Components/Loader/Spinner';
import NotificationService from '../../Components/Notification/Notification';
import ConfirmModal from '../../Components/Modal/ConfirmModal';
import Constant from '../../Global/Constant';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'black',
        fontWeight: 700,
        fontSize: '14px',
        height: '44px',
        padding: 0,
        paddingLeft: '20px',
        backgroundColor: theme.palette.secondary.light
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "0px",
        paddingLeft: '14px',
        height: '44px',
        fontWeight: 400
    },
}));

type AppTableProps = {
    appDetails?: AppDetails,
    getAppDetails: (loaderNotRequired?: boolean) => Promise<any>,
    updateAppDetails: (app: AppDetails) => any;
}

export default function AppTable({ appDetails, getAppDetails, updateAppDetails }: AppTableProps) {
    const [loading, setLoading] = useState(false);
    const notificationRef = useRef<NotificationRefType>(null);
    const deleteModalRef = useRef<DeleteModalRefType>(null);
    const handleClickOnDelete = async (versionId: number, platform: string, appVersion: string) => {
        deleteModalRef.current?.openDeleteModal(
            Constant.deleteVersion,
            Constant.deleteAppVersionConfirm + appVersion + "?",
            Constant.delete,
            { versionId, platform }
        );
    }

    const isViewer = () => {
        return appDetails?.accessType === Constant.viewer
    }

    const isEditor = () => {
        return appDetails?.accessType === Constant.editor
    }

    const handleDeleteConfirm = async ({ versionId, platform }: any) => {
        try {
            setLoading(true);
            await projectService.deleteAppVersion({ versionId, platform });
            await getAppDetails();
            setLoading(false);
            notificationRef.current?.showNotification(Constant.deleteAppVersionSuccess, "success", Constant.twoSeconds);
        }
        catch (error: any) {
            notificationRef.current?.showNotification(error?.message, "error");
            setLoading(false);
        }
    }

    const updatePublish = async (versionId: number, published: boolean) => {
        const app = structuredClone(appDetails);
        if (app && app?.appVersions) {
            const changeIndex = app.appVersions.findIndex((version: AppVersion) => {
                return version.versionId === versionId ? true : false;
            })
            if (changeIndex >= 0) {
                app.appVersions[changeIndex].isActive = published;
                updateAppDetails(app);
            }
        }
    }

    const publishVersion = async (
        versionId: number,
        platform: string,
        published: boolean
    ) => {
        try {
            updatePublish(versionId, published);
            await projectService.publishAppVersion({ versionId, platform, published })
            notificationRef.current?.showNotification(published ? Constant.publishSuccessfull : Constant.unPublishSuccessfull, "success", Constant.twoSeconds);
            await getAppDetails(true);
        }
        catch (error: any) {
            console.error(error?.message)
            notificationRef.current?.showNotification(Utility.getErrorMessage(error), "error");
        }
    }

    const publishDisabled = (appVersion: string, alreadyPublished: boolean) => {
        if (alreadyPublished) return false;
        return appDetails?.appVersions.some(app =>
            app.appVersion === appVersion &&
            app.isActive === true
        );
    }

    const getFileType = () => {
        return appDetails?.appType === "Android" ? Constant.apk : Constant.ipa;
    }


    const EmptyData = () => {
        return <Grid container alignItems={'center'} justifyContent={'center'} height={'10rem'}>
            <Grid item xs={12}>
                <Typography textAlign={'center'} color={'grey'} width={'100%'} whiteSpace={'nowrap'} fontWeight={500}>Please Upload {getFileType()} File</Typography>
            </Grid>
        </Grid>
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ width: "100%", position: 'relative' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>#</StyledTableCell>
                            <StyledTableCell align="left">App Version</StyledTableCell>
                            <StyledTableCell align="left">App Name</StyledTableCell>
                            <StyledTableCell align="left">Hotfix Version</StyledTableCell>
                            <StyledTableCell align="left">Timestamp</StyledTableCell>
                            <StyledTableCell align="center">Publish</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appDetails?.appVersions?.map((row, index) => (
                            <TableRow
                                key={row.versionId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                <StyledTableCell align="left">{row.appVersion}</StyledTableCell>
                                <StyledTableCell align="left">{row.appName}</StyledTableCell>
                                <StyledTableCell align="left">{row.hotfixNumber}</StyledTableCell>
                                <StyledTableCell align="left">{Utility.convertCreatedDate(row.timeStamp)}</StyledTableCell>
                                <StyledTableCell align="center" >
                                    {
                                        <Switch
                                            size='small'
                                            defaultChecked={row.isActive}
                                            disabled={isViewer() || isEditor() || publishDisabled(row.appVersion, row.isActive)}
                                            onChange={(e) => { publishVersion(row.versionId, appDetails.appType, e.target.checked) }}
                                        />
                                    }
                                </StyledTableCell>
                                <StyledTableCell align="center" >
                                    {
                                        <IconButton
                                            size='small'
                                            onClick={() => {
                                                handleClickOnDelete(row.versionId, appDetails.appType, row.appVersion)
                                            }}
                                            disabled={isViewer() || isEditor()}
                                        >
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    }
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Spinner loading={loading} />
                {
                    (!loading && !appDetails?.appVersions?.length) && EmptyData()
                }
            </TableContainer >
            <NotificationService ref={notificationRef} />
            <ConfirmModal handleConfirm={handleDeleteConfirm} ref={deleteModalRef} />
        </>
    );
}