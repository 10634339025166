import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Grid, Modal, Typography, Box, SvgIcon, TextField, Button, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useStyles } from '../../common/theme';
import { RemoveIcon } from '../../assets/images/SvgImages'
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import { NotificationRefType } from '../../Global/Interfaces';
import authService from '../../Services/authService';
import NotificationService from '../Notification/Notification';
import LoaderIcon from '../Loader/LoderIcon';

export default function ChangePasswordModal(props: any) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const changePasswordClose = () => {
        props.setPasswordOpen(false)
        resetStates();
    };

    const resetFormData = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    const updatePassword = async () => {
        try {
            setLoading(true);
            await authService.changePassword(formData);
            notificaitonRef.current?.showNotification(Constant.passwordChangeSuccess, "success", Constant.twoSeconds)
            changePasswordClose();
            setLoading(false);
        }
        catch (error: any) {
            notificaitonRef.current?.showNotification(Utility.getErrorMessage(error), "error");
            setLoading(false);
        }
    }

    const notificaitonRef = useRef<NotificationRefType>(null);

    const [formData, setFormData] = useState(resetFormData);

    const [errors, setErrors] = useState(resetFormData);


    const handleChange = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const { name, value } = event.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: validateField(name, value),
        }));
    };

    const resetStates = () => {
        setFormData(resetFormData);
        setErrors(resetFormData)
    }

    const validateField = (fieldName: string, value: string) => {
        switch (fieldName) {
            case 'currentPassword':
                return value.length < 1 ? Constant.emptyFieldError : '';
            case 'newPassword':
                return value.length < 4 ? Constant.invalidPassword : '';
            case 'confirmPassword':
                return value !== formData.newPassword ? Constant.invalidMatch : '';
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            const newErrors: any = {};
            Object.keys(formData).forEach((fieldName: string) => {
                newErrors[fieldName] = validateField(fieldName, (formData as any)[fieldName]);
            });
            setErrors(newErrors);

            if (Object.values(newErrors).every((error) => error === '')) {
                updatePassword();
            }
        }
        catch (error: any) {
            notificaitonRef.current?.showNotification(error?.message || Constant.defaultErrorMessage, "error");
        }
    };


    const checkError = (field: any) => {
        return field ? true : false;
    }



    return (
        <div>
            <Modal
                open={props.passwordOpen}
                onClose={changePasswordClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <Box sx={{ width: 400 }} className={classes.modalBox}>
                        <Grid direction={'column'} gap={'16px'}>
                            <Grid item>
                                <Typography width={'100%'} variant='fontBoldLarge' >Change Password</Typography >
                                <IconButton
                                    aria-label="close"
                                    onClick={changePasswordClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                    }}
                                >
                                    <SvgIcon sx={{ fontSize: 24 }}>{RemoveIcon}</SvgIcon>
                                </IconButton>
                            </Grid>
                            <Grid textAlign={'center'} item >
                                <TextField
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="currentPassword"
                                    label="Current Password"
                                    type="password"
                                    id="password"
                                    autoComplete={undefined}
                                    helperText={errors.currentPassword}
                                    value={formData.currentPassword}
                                    onChange={handleChange}
                                    error={checkError(errors.currentPassword)}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="newPassword"
                                    label="New Password"
                                    type="password"
                                    id="password"
                                    autoComplete={undefined}
                                    helperText={errors.newPassword}
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    error={checkError(errors.newPassword)}
                                />
                                <TextField
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="password"
                                    autoComplete={undefined}
                                    helperText={errors.confirmPassword}
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    error={checkError(errors.confirmPassword)}
                                />
                                <Button variant='contained' size='small'
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    onClick={handleSubmit}
                                >
                                    <Typography variant='fontBold' color={'white'}>{loading ? <LoaderIcon /> : "Change"}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            </Modal>
            <NotificationService ref={notificaitonRef} />
        </div >
    );
}