import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

type SpinnerType = {
    loading: boolean,
    noBackground?: boolean
}

export default function Spinner({ loading, noBackground }: SpinnerType) {
    const spinnerStyles: any = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        inset: '0',
        display: 'grid',
        placeItems: 'center',
        zIndex: '20',
        backgroundColor: noBackground ? 'none' : 'rgba(0, 0, 0, 0.3)'
    }
    return (
        loading ?
            <div style={{ ...spinnerStyles }}>
                <CircularProgress />
            </div> : <></>
    );
}