import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import PageRoutes from "../../Global/Routes";
import "./style.css";
import authStore from "../../Store/authStore";
import Project from "../../Pages/Project";
import AppVersion from "../../Pages/AppVersion";
import RegisterUser from "../../Pages/Register";
import PageNotFound from "../../Components/PageNotFound/PageNotFound";
const Login = React.lazy(() => import("../../Pages/Login"));

const getaccessRoute = () => {
  const { currentUser } = authStore;
  let redirectTo = PageRoutes.login;
  if (!currentUser) {
    redirectTo = PageRoutes.review;
  }

  return redirectTo;
};

const MainComponent = () => (
  <>
    <Suspense fallback={<></>}>
      <Routes>
        <PrivateRoute path={PageRoutes.project} component={Project} exact />
        <Route path={PageRoutes.project}>
        </Route>
        <Route path="*">
        </Route>
      </Routes>
    </Suspense>
  </>
);

const Router = () => (
  <Suspense fallback={<></>}>
    <Routes>
      <Route path={PageRoutes.project} element={<PrivateRoute component={Project} />} />
      <Route path={PageRoutes.home} element={<Navigate to={PageRoutes.projectDefault} />} />
      <Route path={PageRoutes.app} element={<PrivateRoute component={AppVersion} />} />
      <Route path={PageRoutes.registerUser} element={<RegisterUser />} />
      <Route path={PageRoutes.login} element={<Login />} />
      <Route path={"*"} element={<PageNotFound />} />
    </Routes>
  </Suspense>
);

export default Router;
