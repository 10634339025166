import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
declare module '@mui/material/styles' {
  interface ThemeOptions {
    colors?: {
      primary?: string;
      primaryLight?: string;
      secondary?: string;
    };
  }

  interface TypographyVariants {
    fontPrimary: React.CSSProperties;
    fontBold: React.CSSProperties;
    fontBoldSmall: React.CSSProperties;
    fontBoldLarge: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    fontPrimary?: React.CSSProperties;
    fontBold: React.CSSProperties;
    fontBoldLarge: React.CSSProperties;
    fontBoldSmall: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    fontPrimary: true;
    fontBold: true;
    fontBoldLarge: true;
    fontBoldSmall: true;
  }
}

export const useStyles = makeStyles((theme: Theme) => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '16px',
  },

  input: {
    border: 'none',
    outline: 'none',
    height: '20px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    '&:focus': {
      outline: 'none',
    },
  },

  DragnDrop: {
    minHeight: 'auto !important',
    marginBottom: '24px',
    width: '100%',
    height: '88px !important',
    border: '2px dashed #707070 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent !important',
    '& .MuiTypography-root': {
      color: '#707070 !important',
      fontSize: '16px',
    },
    '& .MuiButton-startIcon': {
      color: '#707070'
    },
    '& .MuiDropzoneArea-icon': {
      display: 'none',
      visibility: 'hidden'
    },
    '& .MuiDropzonePreviewList-root': {
      display: 'none',
      visibility: 'hidden'
    },
  }
}));

const primaryColor = '#1976d2';
const customTheme = createTheme({
  palette: {
    primary: {
      main: 'rgba(97, 147, 246, 1)'
    },
    secondary: {
      main: 'rgba(204, 204, 204, 1)',
      light: 'rgba(224, 224, 224, 1)'
    },
    background: {
      default: '#F5F5F5'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'black',
          borderWidth: '2px',
          borderRadius: '8px',
          padding: '10px 14px !important'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          height: '40px',
          fontWeight: '600',
          textTransform: 'none',
          color: 'white',
          boxShadow: 'none'
        },
        outlined: {
          border: '2px solid black',
          color: 'black',
          '&:hover': {
            borderWidth: '2px',
            color: primaryColor
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          lineHeight: 2.64,
        }
      }
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontPrimary: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '14px'
    },
    fontBoldSmall: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      fontSize: '12px'
    },
    fontBold: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      fontSize: '14px'
    },
    fontBoldLarge: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 700,
      fontSize: '20px'
    },
  },
});

export default customTheme;