import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ListItemText, Typography } from '@mui/material';
import { useStyles } from '../../common/theme';
import ShareProjectModal from '../../Components/Modal/ShareProjectModal';
import { ProjectDetails } from '../../Global/Interfaces';

type ProjectOptionsProps = {
    shared?: boolean,
    deleteProject: () => any,
    openProjectModal: (rename: boolean) => any,
    leaveProject: () => any,
    selectedProject?: ProjectDetails,
    getAllProjects: () => any
}

export default function ProjectOptions({ shared, deleteProject, openProjectModal, leaveProject, selectedProject, getAllProjects }: ProjectOptionsProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteProject = () => {
        deleteProject();
        handleClose();
    }

    const [shareProjectopen, setshareProjectopen] = React.useState(false);
    const handleShareProjectOpen = () => setshareProjectopen(true);
    const handleShareProjectClose = async () => {
        await getAllProjects();
        setshareProjectopen(false);
    }

    const handleLeaveProject = () => {
        leaveProject();
        handleClose();
    }

    const menuListItems = () => {
        return shared ?
            <>
                <MenuItem onClick={handleLeaveProject}>
                    <ListItemText>Leave Project</ListItemText>
                </MenuItem>
            </> :
            <>
                <MenuItem>
                    <ListItemText
                        onClick={() => {
                            openProjectModal(true);
                        }}
                    >
                        Rename
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleShareProjectOpen}>
                    <ListItemText>Share</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteProject}>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>

            </>
    }


    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Typography fontSize={'20px'} color={'black'} fontWeight={600}>{selectedProject?.projectName}</Typography>
                <ArrowDropDownIcon sx={{ color: 'black' }} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '10px'
                    }
                }}
            >
                {menuListItems()}
            </Menu>
            {
                shareProjectopen &&
                <ShareProjectModal
                    handleShareProjectClose={handleShareProjectClose}
                    shareProjectopen={shareProjectopen}
                    selectedProject={selectedProject}
                />
            }
        </div>
    );
}