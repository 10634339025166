import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoaderIcon() {
    return <CircularProgress
        sx={{
            color: 'white',
        }}
        size={20}
    />
}